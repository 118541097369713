// Purpose: To handle a select all / clear checkbox
// This differs from multi_select_table in that this
// is not intended for paginated situations

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "allCheckbox"];

  toggleAll = (e) => {
    if (this.allCheckboxTarget.checked) {
      this.optionTargets.forEach((option) => {
        option.checked = true;
      });
    } else {
      this.optionTargets.forEach((option) => {
        option.checked = false;
      });
    }
  }

  clearAll = (e) => {
    this.allCheckboxTarget.checked = false;
  }
}
