import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "dropdown"];

  connect() {

  };

  changePreviewLink = (e) => {
    if (this.dropdownTarget.value !== "") {
      this.triggerTarget.classList.remove("tw-hidden");
      this.triggerTarget.href = `/e/${this.dropdownTarget.value}`;
    } else {
      this.triggerTarget.classList.add("tw-hidden");
    }
  }
}
