import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["organizations"];

  updateEventTypes = (e) => {
    const organizationId = this.organizationsTarget?.value;
    if (e?.detail.field_name == "organization_id" && organizationId) {
      get(`/admin/organizations/${organizationId}/event_types`, { responseKind: "turbo-stream" });
    }
  };
}
